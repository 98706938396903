
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "../../../node_modules/bootstrap-daterangepicker/daterangepicker.css";
@import "../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
@import "../../../node_modules/select2/dist/css/select2.min.css";

@media (min-width: 1300px) {
    .container {
        width: 1270px;
    }
}

.panel--options {
    background-color: #FCFCFC;
    border-bottom: 1px solid $panel-default-border;
}

.datepicker-inline {
    background-color: $body-bg;

    .day {
        color: #292d2f;
    }
}

.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
    color: #bfbfbf;
}

.actions {
    width: 1%;
    white-space: nowrap;

    button {
        opacity: 0;
    }
}

tr.template {
    display: none;
}

tr:hover {
    .actions button {
        opacity: 1;
    }
}
